import { Router } from '@reach/router';
import PropTypes from 'prop-types';
import React from 'react';
import { PrivateRoute } from 'ui/common';
import {
  Account,
  AccountEstate,
  AccountPayGuard,
  Login,
  NotFound,
  Signup,
  Verification,
} from 'ui/screens';

const AccountPage = ({ location }) => {
  return (
    <Router basepath='/account'>
      <Login path='/login' />
      <Signup path='/signup' />
      <Verification path='/verification' />
      <PrivateRoute path='/' component={Account} />
      <PrivateRoute path='/estates/:id' component={AccountEstate} />
      <PrivateRoute path='/pay/guard/:taekiaId' component={AccountPayGuard} />
      <NotFound default />
    </Router>
  );
};

AccountPage.propTypes = {
  location: PropTypes.object,
};

AccountPage.defaultProps = {};

export default AccountPage;
